import React, { useState } from 'react';
import { Button, Col, Row, Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import StatusCard from './StatusCard';
import IcoMoon from '../../Utils/Icon/IcoMoon';
import { statusUpdateSolicitudeRequest } from '../../../requests/solicitudes';
import useConfirmModal from '../../../hooks/useConfirmModal';
import {
  solicitudeStatusButtonColor,
  statusToButtonText
} from '../../../services/utils';
import { sendAlert } from '../../../actions/utils';
import { UploadImage } from '../../Utils/Upload';
import Getter from '../../../utils/getter';

const StatusBox = ({
  pickupPersonName,
  pickupPersonPhone,
  deliveryPersonName,
  deliveryPersonPhone,
  pickupAddressAttributes,
  deliveryAddressAttributes,
  shipping
}) => {
  const dispatch = useDispatch();
  const [imgFile, setImgFile] = useState(null);
  const [evidenceModal, setEvidenceModal] = useState(false);
  const [status, setStatus] = useState(shipping.solicitude.status);

  const [translatedStatus, setTranslatedStatus] = useState(
    shipping?.solicitude?.statusTranslated
  );
  const [showWarningCard, setShowWarningCard] = useState(true);
  const handleUpdateStatus = () => {
    statusUpdateSolicitudeRequest(shipping.solicitude.id, {
      dispatch,
      successCallback: response => {
        setTranslatedStatus(response.data.status_translated);
        setStatus(response.data.status);
        dispatch(
          sendAlert({
            kind: 'success',
            message: 'Estado actualizado correctamente'
          })
        );
      }
    });
  };
  const handleClose = () => {
    setEvidenceModal(false);
  };
  const buttonText = statusToButtonText[status];

  const shouldBeDisabled = ['delivered', 'finished'].includes(status);
  const handleEvidence = async () => {
    const sol = await Getter(
      'PUT',
      `/solicitudes/${shipping.solicitude.id}/status_update`,
      {
        status: 'delivered',
        evidence: imgFile
      },
      'form-data'
    );
    dispatch(
      sendAlert({
        kind: 'success',
        message: 'Estado actualizado correctamente'
      })
    );
    setStatus('delivered');
    console.log('SOL', sol);
  };

  const { ModalComponent, handleShowModal } = useConfirmModal({
    modalTitle: 'Actualizar estado',
    onConfirmCallback: handleUpdateStatus,
    modalBody: (
      <div className="p-5">
        <h6>¿Estás seguro de actualizar el estado actual?</h6>
      </div>
    )
  });
  console.log(status, shipping);
  return (
    <>
      <Col sm={12} md={8} lg={8}>
        <Col className="status-box shadow-black">
          <div>
            <Row className="py-4">
              <StatusCard
                personName={pickupPersonName}
                personPhone={pickupPersonPhone}
                addressAttributes={pickupAddressAttributes}
                shipping={shipping}
                shippingStatus={translatedStatus}
              />
              <StatusCard
                isDeliverCard
                personName={deliveryPersonName}
                personPhone={deliveryPersonPhone}
                addressAttributes={deliveryAddressAttributes}
                shipping={shipping}
                shippingStatus={translatedStatus}
              />
              <div className="px-3">
                <h6>Estado: {translatedStatus}</h6>
              </div>
            </Row>
          </div>
        </Col>
        <Row className="justify-content-between form-client-solicitude mt-3 mb-5">
          <Col md={6} lg={6} xs={12}>
            {showWarningCard && (
              <div className="card-warning">
                <div className="title-card d-flex justify-content-between align-items-center">
                  <h6>Recuerda notificar el cambio de los estados</h6>
                  <IcoMoon
                    icon="cancel-circle"
                    className="close-warning-card-icon"
                    onClick={() => setShowWarningCard(false)}
                  />
                </div>
              </div>
            )}
          </Col>
          <Col md={6} lg={6} xs={12}>
            <Button
              variant={solicitudeStatusButtonColor[status] || 'info'}
              className="btn-block"
              style={{ height: '100%' }}
              onClick={
                shipping.solicitude?.status == 'to_be_delivered' ||
                status == 'to_be_delivered'
                  ? setEvidenceModal
                  : handleShowModal
              }
              disabled={shouldBeDisabled}
            >
              {buttonText}
            </Button>
          </Col>
        </Row>
      </Col>
      <ModalComponent />
      <Modal
        show={evidenceModal}
        onHide={handleClose}
        backdrop="static"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-center">Actualizar estado</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center p-5">
          ¡Bien hecho!
          <br />
          Sube la foto del paquete entregado para celebrar tu éxito.
          <br />
          ¡Sigue así!
          <br />
          <UploadImage
            onChange={image => {
              // console.log(image)
              setImgFile(image);
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              handleEvidence();
              setEvidenceModal(false);
            }}
          >
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default StatusBox;
