/* eslint-disable consistent-return */
import React, { useState, useEffect } from 'react';
import { Button, Row, Spinner } from 'react-bootstrap';
import { Field, Form, withFormik } from 'formik';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { BasicTextArea } from '../Input';
import RatingControlled from './RatingControlled';
import createRatingRequest from '../../../requests/ratings';

let numberOfStars = 0;
let rankableId;
let onRequestRating;
let updateRatingCell;
let starRequired;
let messageRequired;
let activateToggleModal;

const RatingForm = ({
  quotationId,
  onHide,
  isValid,
  errors,
  touched,
  onRequest,
  isFetching,
  updateRating,
  toggleModal
}) => {
  const [rating, setRating] = useState(undefined);
  const [showStarRequiredMessage, setShowStarRequiredMessage] = useState(false);
  const [showRequiredMessage, setShowRequiredMessage] = useState(false);
  const [updatedisValid, setUpdatedisValid] = useState(isValid);

  const toggleStarRequiredMessage = () => {
    setShowStarRequiredMessage(!showStarRequiredMessage);
  };

  useEffect(() => {
    numberOfStars = rating;
    if (rating > 3) {
      setUpdatedisValid(true);
      setShowStarRequiredMessage(false);
      setShowRequiredMessage(false);
    }
    if (rating < 4) {
      setShowStarRequiredMessage(false);
      setShowRequiredMessage(true);
    }
  }, [rating]);

  rankableId = quotationId;
  onRequestRating = onRequest;
  updateRatingCell = updateRating;
  starRequired = () => toggleStarRequiredMessage();
  messageRequired = () => setShowRequiredMessage(true);
  activateToggleModal = toggleModal;

  return (
    <div className="rating-detail d-flex flex-column justify-content-center align-items-center p-2">
      <Row className="d-flex justify-content-center w-100">
        <h6>Califica a tu Briiinger</h6>
      </Row>

      <Row className="d-flex flex-column justify-content-start align-items-center w-100">
        <RatingControlled
          setRating={setRating}
          value={rating}
          text="Clasificación"
        />
        {showStarRequiredMessage && (
          <div className="opacity-animation w-100">
            <p className="m-4 text-secondary text-center">
              Por favor selecciona la cantidad de estrellas
            </p>
          </div>
        )}
        {showRequiredMessage && (
          <div className="opacity-animation w-100">
            <p className="m-4 text-secondary text-center">
              Si tu evaluacion es inferior a 3 estrellas, por favor escribe un
              comentario.
            </p>
          </div>
        )}
        <p className="m-4">
          Escribe tu comentario sobre el Briiinger y su proceso
        </p>

        <Form className="w-100">
          <Field name="body">
            {({ field }) => (
              <BasicTextArea
                {...field}
                rows={3}
                size={10}
                placeholder="Escribe tu comentario del Briiing..."
                error={errors[field.name]}
                touched={touched[field.name]}
              />
            )}
          </Field>

          <Row className="d-flex justify-content-center w-100 my-2  mx-0">
            <Button
              type="submit"
              variant="success"
              className="btn-block m-0"
              disabled={!updatedisValid}
              onClick={onHide}
            >
              {isFetching ? (
                <Spinner
                  as="span"
                  animation="border"
                  className="mr-2"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                'Enviar'
              )}
            </Button>
          </Row>
        </Form>
      </Row>
    </div>
  );
};

const handleSubmit = (values, { props }) => {
  onRequestRating();
  const { dispatch } = props;
  // const { id } = props.match.params;
  const { body } = values;
  const rating = {
    body,
    number_of_stars: numberOfStars,
    rankable_id: rankableId,
    rankable_type: 'Quotation'
  };
  if (rating.number_of_stars === undefined) {
    onRequestRating();
    return starRequired();
  }
  if (rating.number_of_stars < 4) {
    if (body.length === 0) {
      onRequestRating();
      return messageRequired();
    }
  }

  createRatingRequest({
    dispatch,
    params: rating,
    successCallback: response => {
      activateToggleModal();
      setTimeout(() => {
        onRequestRating();
        updateRatingCell(camelCaseRecursive(response.data));
      }, 600);
    }
  });
};

const initialValues = {
  body: ''
};

const validationSchema = Yup.object().shape({});

const mapStateToProps = state => {
  return state;
};

export default withRouter(
  connect(mapStateToProps)(
    withFormik({
      mapPropsToValues: () => initialValues,
      validationSchema,
      handleSubmit
    })(RatingForm)
  )
);
