import React from 'react';
import { Image } from 'react-bootstrap';
import logoClient from './logo-client.png';
import logoDriver from './logo-driver.png';
import logo from './logo.png';

/**
 * @param {{loginRole: 'client' | 'driver'}} param0
 */
const MainLogo = ({ loginRole }) => {
  let bLogo = logo;
  if (loginRole) {
    bLogo = loginRole === 'client' ? logoClient : logoDriver;
  }
  return (
    <Image
      src={bLogo}
      alt="Logo Briiing!"
      className="d-inline-block align-top"
    />
  );
};

export default MainLogo;
