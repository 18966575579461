import { authTypes } from '../actions/auth';

const initialState = {
  user: {
    id: 0,
    email: '',
    currentRoles: ''
  },
  headers: {
    accessToken: ''
  },
  signedIn: localStorage.jwt !== undefined,
  ongoingRequest: {
    signIn: false,
    signUp: false,
    signOut: false
  }
};

const setHeaders = action => ({
  accessToken: action.response.headers.get('jwt')
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case authTypes.SIGN_IN_REQUEST:
      return {
        ...state,
        ongoingRequest: { ...state.ongoingRequest, signIn: true }
      };
    case authTypes.SIGN_IN_FORCED:
      console.log(state, action);
      return {
        ...state,

        ongoingRequest: { ...state.ongoingRequest, signIn: true }
      };
    case authTypes.SIGN_IN_SUCCESS:
      return {
        ...state,
        user: action.result,
        headers: setHeaders(action),
        ongoingRequest: { ...state.ongoingRequest, signIn: false },
        signedIn: true
      };
    case authTypes.SIGN_IN_FAILURE:
      return {
        ...state,
        ongoingRequest: { ...state.ongoingRequest, signIn: false }
      };
    case authTypes.SIGN_UP_REQUEST:
      return {
        ...state,
        ongoingRequest: { ...state.ongoingRequest, signUp: true }
      };
    case authTypes.SIGN_UP_SUCCESS:
      return {
        ...state,
        user: action.result,
        headers: setHeaders(action),
        ongoingRequest: { ...state.ongoingRequest, signUp: false },
        signedIn: true
      };
    case authTypes.SIGN_UP_FAILURE:
      return {
        ...state,
        ongoingRequest: { ...state.ongoingRequest, signIn: false }
      };
    case authTypes.SIGN_OUT_REQUEST:
      return {
        ...state,
        ongoingRequest: { ...state.ongoingRequest, signOut: true }
      };
    case authTypes.SIGN_OUT_SUCCESS:
      return {
        ...initialState,
        ongoingRequest: { ...state.ongoingRequest, signOut: false },
        signedIn: false
      };
    case authTypes.SIGN_OUT_FAILURE:
      return {
        ...state,
        ongoingRequest: { ...state.ongoingRequest, signOut: false }
      };
    case authTypes.VALIDATE_TOKEN_REQUEST:
      return {
        ...state
      };
    case authTypes.VALIDATE_TOKEN_SUCCESS:
      return {
        ...state,
        user: action.user,
        headers: setHeaders(action)
      };
    case authTypes.RESTORE_AUTH_INFO:
      return {
        ...state,
        headers: action.auth,
        signedIn: true
      };
    case authTypes.CLEAR_AUTH_INFO:
      localStorage.removeItem('jwt');
      return {
        ...state,
        user: initialState.user,
        headers: initialState.headers,
        signedIn: false
      };
    default:
      return state;
  }
};

export default reducer;
