import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Spinner,
  OverlayTrigger,
  Tooltip,
  Modal
} from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import IcoMoon from '../../Utils/Icon';
import showMeRequest from '../../../requests/auth';
import useConfirmModal from '../../../hooks/useConfirmModal';
import { statusUpdateSolicitudeRequest } from '../../../requests/solicitudes';
import { sendAlert } from '../../../actions/utils';
import Getter from '../../../utils/getter';
import RatingForm from '../../Utils/Rating/RatingForm';

const RouteDescription = ({
  roleProfile,
  pickupStartDate,
  deliveryEndDate,
  deliveryAddressAttributes,
  pickupAddressAttributes,
  totalValue,
  solicitudeId,
  isQuotation,
  hasQuotation,
  quotationPaid,
  status,
  translatedStatus,
  hasMyOffer,
  amIBestQuotation,
  parsedLowestOfferAmount,
  parsedHighestOfferAmount,
  quotationCount,
  locationUrl,
  pickupOption,
  deliveryOption,
  solicitude
}) => {
  const { streetName } = pickupAddressAttributes;
  const { streetName: deliverystreetName } = deliveryAddressAttributes;
  const dispatch = useDispatch();
  const isDriver = roleProfile === 'driver';
  const offerUrl = {
    pathname: `/driver/quotations/${solicitudeId}/new`,
    state: locationUrl
  };

  const completeProfileUrl = `/driver/profile/edit`;
  const { user } = useSelector(state => state.auth);
  const [hasVehicles, setHasVehicles] = useState(false);
  const [onFetching, setOnFetching] = useState(false);
  const [offerButtonText, setOfferButtonText] = useState('Ofertar');
  const [bestOfferText, setBestOfferText] = useState('¡Eres la mejor oferta!');
  const [rangeOffersMsg, setRangeOffersMsg] = useState(
    'Aún no existen ofertas. ¡Sé el primero en ofertar!'
  );
  const isValidated = user?.driver_attributes?.is_validated ?? false;
  const offerBtnDisabled = !hasVehicles || !isValidated;

  useEffect(() => {
    if (hasQuotation) {
      if (hasMyOffer) setOfferButtonText('Reofertar');
      if (!amIBestQuotation) {
        setBestOfferText('No eres la mejor oferta.');
      }
      setRangeOffersMsg(
        `Tu competencia ha ofertado entre ${parsedLowestOfferAmount} / ${parsedHighestOfferAmount}`
      );
    }
  }, []);

  const history = useHistory();

  const goToProfileEdit = () => {
    history.push(completeProfileUrl);
  };

  const fetchVehicles = () => {
    setOnFetching(true);
    showMeRequest({
      dispatch,
      successCallback: response => {
        setHasVehicles(
          response.data.user_info.driver_attributes.vehicles_attributes
            ?.length > 0
        );
        setOnFetching(false);
      }
    });
  };
  const [isFetching, setIsFetching] = useState(false);
  const onRequest = () => {
    setIsFetching(!isFetching);
  };

  const toggleModal = () => {
    setShowRatingForm(!showRatingForm);
  };

  useEffect(() => {
    if (isDriver) fetchVehicles();
  }, []);

  const renderTooltip = props => {
    if (isValidated) return <div />;
    return (
      <Tooltip id="button-tooltip" {...props}>
        ¡Pendiente de confirmación Briiing!
      </Tooltip>
    );
  };
  const [showRatingForm, setShowRatingForm] = useState(false);
  const [shippingStatus, setShippingStatus] = useState(status);
  const [translatedShippingstatus, setTranslatedShippingStatus] = useState(
    translatedStatus
  );

  const handleConfirmDelivered = () => {
    statusUpdateSolicitudeRequest(solicitudeId, {
      dispatch,
      successCallback: response => {
        setShowRatingForm(true);
        setShippingStatus(response.data.status);
        setTranslatedShippingStatus(response.data.status_translated);
        dispatch(
          sendAlert({
            kind: 'success',
            message: 'Has confirmado la entrega de tu carga.'
          })
        );
      }
    });
  };

  const shouldRenderConfirmButton = shippingStatus === 'delivered';
  const shouldBeDisabled = shippingStatus === 'finished';
  const { handleShowModal, ModalComponent } = useConfirmModal({
    modalTitle: 'Confirmar entrega',
    onConfirmCallback: handleConfirmDelivered,
    modalBody: (
      <div className="p-5">
        <h6>¿Estás seguro de querer confirmar la entrega?</h6>
      </div>
    )
  });
  const [modal, setModal] = useState(false);
  const [imageS3, setImage] = useState(null);
  const verEvidencia = async url => {
    setModal(true);
    const image = await Getter('POST', '/image_s3', {
      url: url.replace('https://briiing-prod-bucket.s3.amazonaws.com/', '')
    });
    setImage(image.url);
  };
  console.log(solicitude);
  return (
    <div className="d-flex flex-column justify-content-start align-items-start w-100 bg-green">
      <div className="d-flex w-100 justify-content-start">
        <div className="d-flex flex-column justify-content-center align-items-center icons-box">
          <div className="dotted-line" />
          <IcoMoon className="mb-4" size="22" icon="radio-checked" />
          <IcoMoon className="mt-4" size="22" icon="location1" />
        </div>
        <div className="d-flex flex-column justify-content-between align-items-start">
          <div className="d-flex flex-column m-2">
            <p className="font-weight-bold">{streetName}</p>
            <p>
              <span className="mr-1">Fecha Salida:</span>
              {pickupOption === 'soon_as_possible'
                ? 'Lo antes posible'
                : pickupStartDate}
            </p>
          </div>
          <div className="d-flex flex-column m-2">
            <p className="font-weight-bold">{deliverystreetName}</p>
            <p>
              <span className="mr-1">Fecha Llegada:</span>
              {deliveryOption === 'soon_as_possible'
                ? 'Lo antes posible'
                : deliveryEndDate}
            </p>
          </div>
        </div>
      </div>
      {isDriver ? (
        <div className="d-flex flex-column w-100 justify-content-start align-items-start ">
          <div
            className="state-box mt-3 flex-column"
            style={{ background: '#ffe0d1' }}
          >
            {quotationCount > 0 && (
              <p className="px-1 text-center font-weight-bold w-100">
                {bestOfferText}
              </p>
            )}
            <p className="px-1 text-center w-100">{rangeOffersMsg}</p>
          </div>
          {!isQuotation && (
            <>
              {onFetching ? (
                <div className="mx-auto mt-4">
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                </div>
              ) : (
                <>
                  {!hasMyOffer && (
                    <OverlayTrigger
                      placement="top"
                      delay={{ show: 250, hide: 400 }}
                      overlay={renderTooltip}
                    >
                      <div className="mt-4 d-flex justify-content-center w-100">
                        <Button
                          className={`make-offer-btn ${
                            offerBtnDisabled ? 'disabled' : ''
                          } `}
                          variant="success"
                          as={Link}
                          to={offerUrl}
                        >
                          {offerButtonText}
                        </Button>
                      </div>
                    </OverlayTrigger>
                  )}

                  {!hasVehicles && (
                    <>
                      <div
                        aria-hidden="true"
                        onClick={() => goToProfileEdit()}
                        className="mt-3 d-flex justify-content-center text-btn mx-auto"
                      >
                        <p className="text-black">
                          Para ofertar por favor ingresa un vehículo.
                        </p>
                      </div>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </div>
      ) : (
        <>
          {hasQuotation && quotationPaid && (
            <div className="w-100">
              <div className="d-flex justify-content-between align-items-baseline w-100">
                <div>
                  <h6 className="text-confirmed">
                    Estado solicitud: {translatedShippingstatus}{' '}
                    {translatedShippingstatus == 'Entregado' &&
                      solicitude.evidenceUrl &&
                      solicitude.evidenceUrl != '' && (
                        <button
                          target="_blank"
                          className="btn btn-small btn-light ms-2"
                          onClick={() => verEvidencia(solicitude.evidenceUrl)}
                        >
                          Ver Evidencia
                        </button>
                      )}
                  </h6>
                </div>
                <div className="total-value-box paid">
                  <h6 className="text-confirmed">
                    Monto pagado: ${totalValue.toLocaleString()}
                  </h6>
                </div>
              </div>
              {shouldRenderConfirmButton && (
                <div className="d-flex justify-content-end align-items-center">
                  <h6 className="mt-3 mr-auto text-confirmed">
                    Confirmar para poder hacer efectivo el pago a tu briiinger
                  </h6>
                  <Button
                    variant="secondary"
                    className="mt-3"
                    style={{ height: '100%' }}
                    onClick={handleShowModal}
                    disabled={shouldBeDisabled}
                  >
                    Confirmar entrega
                  </Button>
                </div>
              )}
              <ModalComponent />
              <Modal
                show={modal}
                onHide={() => setModal(false)}
                backdrop="static"
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <Modal.Header closeButton>
                  <Modal.Title className="text-center">Evidencia</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center p-5">
                  {!imageS3 ? (
                    <Spinner animation="border" variant="primary" />
                  ) : (
                    <img className="img-fluid" src={imageS3} />
                  )}
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="primary"
                    onClick={() => () => setModal(false)}
                  >
                    OK
                  </Button>
                </Modal.Footer>
              </Modal>
              <Modal
                show={showRatingForm}
                onHide={() => setShowRatingForm(false)}
                backdrop="static"
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <Modal.Header closeButton>
                  <Modal.Title className="text-center">
                    Califica Tu Envio
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center p-5">
                  <RatingForm
                    quotationId={solicitude?.selectedQuotation?.id}
                    solicitudeId={solicitudeId}
                    toggleModal={setShowRatingForm}
                    onRequest={onRequest}
                    isFetching={isFetching}
                    // updateRating={updateRating}
                  />
                </Modal.Body>
              </Modal>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default RouteDescription;
