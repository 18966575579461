import React from 'react';
import {Switch} from 'react-router-dom';
import {
  LoggedLayout,
  PublicLayout,
  NonLoggedLayout,
} from './layouts';
import {
  ClientHome,

  DriverHome,
  HomeContact,
  ResetPassword,
  Page,
  ProfileDriver,
  ProfileClient,
  ProfileClientEdit,
  ProfileDriverEdit,
  Landing,
  Login,
  Register,
  StepsRegister,
  RecoverPassword,
  ClientSolicitudeIndex,
  ClientSolicitudeNew,
  ClientSolicitudePayment,
  ClientSolicitudeShow,
  ClientSolicitudeEdit,
  DriverShipmentShow,
  DriverSolicitudeIndex,
  DriverSolicitudeTripShow,
  DriverSolicitudeOfferShow,
  SelectSession,
  DriverQuotationNew,
  DriverQuotationIndex,
  DriverQuotationShow,
  DriverShipmentIndex,
  DriverTripIndex,
  DriverTripShow,
  ClientShippmentsIndex,
  PaymentOrderConfirmation,
} from '../screens';
import HomeNotYet from '../screens/Home/HomeNotYet';
import Confirm from '../screens/Home/Confirm';

const routes = (
  <Switch>
    <LoggedLayout module="driver" exact path="/driver/home" component={DriverHome}/>
    <LoggedLayout module="driver" exact path="/driver/profile" component={ProfileDriver}/>
    <LoggedLayout module="driver" exact path="/driver/profile/edit" component={ProfileDriverEdit}/>
    <LoggedLayout module="driver" exact path="/driver/solicitudes" component={DriverSolicitudeIndex}/>
    <LoggedLayout module="driver" exact path="/driver/solicitudes/:id/offer" component={DriverSolicitudeOfferShow}/>
    <LoggedLayout module="driver" exact path="/driver/solicitudes/:id/trip" component={DriverSolicitudeTripShow}/>
    <LoggedLayout module="driver" exact path="/driver/quotations/:solicitudeId/new" component={DriverQuotationNew}/>
    <LoggedLayout module="driver" exact path="/driver/quotations" component={DriverQuotationIndex}/>
    <LoggedLayout module="driver" exact path="/driver/quotations/:id" component={DriverQuotationShow}/>
    <LoggedLayout module="driver" exact path="/driver/shipments" component={DriverShipmentIndex}/>
    <LoggedLayout module="driver" exact path="/driver/shipments/:id" component={DriverShipmentShow}/>
    <LoggedLayout module="driver" exact path="/driver/trips" component={DriverTripIndex}/>
    <LoggedLayout module="driver" exact path="/driver/trips/:id" component={DriverTripShow}/>
    <LoggedLayout module="driver" exact path="/driver/stepsregister" component={StepsRegister} hideNavigation/>
    <LoggedLayout module="driver" exact path="/driver/reset_password" component={ResetPassword} hideNavigation/>

    <LoggedLayout module="client" exact path="/client/home" component={ClientHome}/>
    <LoggedLayout module="client" exact path="/client/solicitudes/new" component={ClientSolicitudeNew} />

    <LoggedLayout module="client" exact path="/client/solicitudes/:id" component={ClientSolicitudeShow} />
    <LoggedLayout module="client" exact path="/client/solicitudes/:id/:quotationId/payment" component={ClientSolicitudePayment}/>
    <LoggedLayout module="client" exact path="/client/solicitudes/:id/edit" component={ClientSolicitudeEdit}/>
    <LoggedLayout module="client" exact path="/client/solicitudes" component={ClientSolicitudeIndex}/>
    <LoggedLayout module="client" exact path="/client/shipments" component={ClientShippmentsIndex}/>
    <LoggedLayout module="client" exact path="/client/profile" component={ProfileClient}/>
    <LoggedLayout module="client" exact path="/client/profile/edit" component={ProfileClientEdit}/>

    <NonLoggedLayout exact path="/login" component={Login}/>
    <NonLoggedLayout exact path="/register" component={Register}/>
    <NonLoggedLayout exact path="/reset_password" component={ResetPassword}/>
    <NonLoggedLayout exact path="/recover_password" component={RecoverPassword}/>
    <PublicLayout exact path="/payment_confirmation" component={PaymentOrderConfirmation} hideNavigation/>
    <PublicLayout exact path="/" component={Landing}/>
    <PublicLayout exact path="/session" component={SelectSession} />
    <PublicLayout exact path="/contact" component={HomeContact} />
    <PublicLayout exact path="/not-yet" component={HomeNotYet} />
    <PublicLayout exact path="/confirm/:id" component={Confirm}  />
    <PublicLayout path="/:page" component={Page}/>
  </Switch>
);

export default routes;
