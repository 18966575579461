/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Row, Col, Button, Modal } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { Field, Form, withFormik, getIn } from 'formik';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { FormikInput } from '../../../components/Utils/Input';
import { FormikSelect, FormikCheckBox } from '../../../components';
import { vehiclesTypes } from '../../Driver/Profile/formOptions';

const VehiclesAttributes = ({
  errors,
  touched,
  modelName,
  onHide,
  isValid,
  setFieldValue,
  setFieldTouched,
  user,
  myState,
  values,
  setStep
}) => {
  const index = 0;
  const { vehicleType } = camelCaseRecursive(
    myState.user?.driverAttributes?.vehiclesAttributes[0]
  );
  const [show, setShow] = useState(false);
  const history = useHistory();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  console.log('STATE', values);
  return (
    <div className="opacity-animation steps_register">
      <h5 className="text-center">Datos del vehículo</h5>
      <Form className="scrollable">
        <Row>
          <Col md={6}>
            <Field
              name={`${modelName}[vehiclesAttributes][${index}][car_make]`}
            >
              {({ field }) => (
                <FormikInput
                  {...field}
                  abbr
                  inputType="text"
                  setFieldValue={setFieldValue}
                  label="Marca"
                  placeholder="Ingresa la marca"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={6}>
            <Field
              name={`${modelName}[vehiclesAttributes][${index}][car_model]`}
            >
              {({ field }) => (
                <FormikInput
                  {...field}
                  abbr
                  inputType="text"
                  setFieldValue={setFieldValue}
                  label="Modelo"
                  placeholder="Ingresa el modelo"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Field
              name={`${modelName}[vehiclesAttributes][${index}][car_year]`}
            >
              {({ field }) => (
                <FormikInput
                  {...field}
                  abbr
                  inputType="number"
                  label="Año de tu vehículo"
                  setFieldValue={setFieldValue}
                  placeholder="Ej.: 2022"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={6}>
            <Field
              name={`${modelName}[vehiclesAttributes][${index}][license_plate]`}
            >
              {({ field }) => (
                <FormikInput
                  {...field}
                  abbr
                  inputType="text"
                  setFieldValue={setFieldValue}
                  label="Patente"
                  placeholder="Ingresa la patente"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Field
              name={`${modelName}[vehiclesAttributes][${index}][vehicle_type]`}
            >
              {({ field }) => (
                <FormikSelect
                  {...field}
                  abbr
                  label="Tipo de vehículo"
                  placeholder="Escoge tipo de vehículo"
                  options={vehiclesTypes}
                  defaultValue={vehicleType}
                  onChange={data =>
                    setFieldValue(field.name, data ? data.value : '')
                  }
                  setFieldTouched={() => setFieldTouched(field.name)}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
        </Row>

        <h6 className="my-4">Medidas (Opcional)</h6>
        <Row>
          <Col md={12}>
            <Field
              name={`${modelName}[vehiclesAttributes][${index}][no_medidas]`}
            >
              {({ field }) => (
                <FormikCheckBox
                  {...field}
                  abbr="true"
                  field={field}
                  onChange={e => {
                    setFieldValue(field.name, e.target.checked);
                  }}
                  className="mt-3"
                  label="Omitir (recomendable para vehículos de mayor tamaño)"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
        </Row>
        {values.user?.driverAttributes?.vehiclesAttributes[index]
          .no_medidas || (
          <Row>
            <Col md={4}>
              <Field
                name={`${modelName}[vehiclesAttributes][${index}][height]`}
              >
                {({ field }) => (
                  <FormikInput
                    {...field}
                    inputType="number"
                    label="Altura (cm)"
                    placeholder="0 cm"
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
            <Col md={4}>
              <Field
                name={`${modelName}[vehiclesAttributes][${index}][length]`}
              >
                {({ field }) => (
                  <FormikInput
                    {...field}
                    inputType="number"
                    label="Largo (cm)"
                    placeholder="0 cm"
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
            <Col md={4}>
              <Field name={`${modelName}[vehiclesAttributes][${index}][width]`}>
                {({ field }) => (
                  <FormikInput
                    {...field}
                    inputType="number"
                    label="Ancho (cm)"
                    placeholder="0 cm"
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
          </Row>
        )}

        <Button
          variant="primary"
          size="lg"
          className="btn mb-2 login-btn driver-btn"
          block
          type="submit"
          disabled={!isValid}
          onClick={onHide}
        >
          Siguiente
        </Button>
        <div className="my-4 d-flex justify-content-center reset-password-btn driver">
          <Button
            variant="outline"
            className="text-black"
            onClick={() => handleShow()}
          >
            Omitir
          </Button>
        </div>
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title className="text-center">Omitir</Modal.Title>
          </Modal.Header>
          <Modal.Body className="text-center p-5">
            Por temas de seguridad no podrás usar algunas funciones de Briiing
            si no tienes tu información al día.
            <br />
            ¿Estas seguro de esto?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              No, quiero completar mis datos.
            </Button>
            <Button
              variant="primary"
              onClick={() => history.push('/driver/home')}
            >
              Si, quiero omitir.
            </Button>
          </Modal.Footer>
        </Modal>
      </Form>
    </div>
  );
};

const setInitialValues = props => {
  return props.myState;
};

const validationSchema = Yup.object().shape({
  user: Yup.object().shape({
    driverAttributes: Yup.object().shape({
      vehiclesAttributes: Yup.array().of(
        Yup.object().shape({
          car_make: Yup.string().required(
            'Debes ingresar la marca de tu vehículo'
          ),
          car_model: Yup.string().required('Debes ingresar un modelo'),
          car_year: Yup.number()
            .required('Debes ingresar el año de tu vehículo')
            .test(
              'len',
              'El año debe ser de 4 dígitos',
              val => val?.toString().length === 4
            )
            .min(1960, 'El año debe ser superior a la fecha ingresada')
            .max(new Date().getFullYear(), 'El año debe ser menor o actual'),
          license_plate: Yup.string().required(
            'Debes ingresar la patente de tu vehículo'
          ),
          vehicle_type: Yup.string().required(
            'Debes ingresar el tipo de vehículo'
          ),
          height: Yup.number().when('no_medidas', {
            is: val => {
              return !val;
            },
            then: Yup.number()
              .required('Debes ingresar un alto')
              .typeError('Debes ingresar solo números')
              .positive('Debe ser mayor a 0')
          }),
          width: Yup.number().when('no_medidas', {
            is: val => {
              return !val;
            },
            then: Yup.number()
              .required('Debes ingresar un ancho')
              .typeError('Debes ingresar solo números')
              .positive('Debe ser mayor a 0')
          }),
          length: Yup.number().when('no_medidas', {
            is: val => {
              return !val;
            },
            then: Yup.number()
              .required('Debes ingresar un largo')
              .typeError('Debes ingresar solo números')
              .positive('Debe ser mayor a 0')
          })
        })
      )
    })
  })
});

const handleSubmit = (values, { props }) => {
  const { updateState, setStep } = props;
  updateState(values);
  setStep(2);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit
})(VehiclesAttributes);
